import React from 'react';
import '../styles/app.scss';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import SidebarCategories from '../components/sidebar/Categories';
import Search from '../components/sidebar/Search';
import Archives from '../components/sidebar/Archives';
{/*import Schedule from '../components/sidebar/Schedule';*/}
import Shop from '../components/sidebar/Shop';
import LineFull from '../images/linefull.jpg';
import SEO from '../components/seo';

const blog = ({ data }) => {
    const { wordpressPost: post } = data;
    const test_title = data.wordpressPost.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return (
        <>
            <SEO
                title={new_seo_title}
                meta={data.wordpressPost.yoast_meta}
                description={data.wordpressPost.excerpt}
            />
            <HeaderMain />
            <section className="innerbanner">
                <div className="container">
                    <h1>Read Our Blog</h1>
                </div>
            </section>

            <section className="page-section">
                <div className="container blogwrapper blogarticle">
                    <div className="bloglft">
                        <div className="blogimg">
                            <img
                                src={
                                    data.wordpressPost.featured_media.localFile
                                        .childImageSharp.fluid.src
                                }
                                alt="featured"
                            />
                        </div>
                        <div className="bloxexc">
                            <h2
                                dangerouslySetInnerHTML={{
                                    __html: data.wordpressPost.title,
                                }}
                            />
                            <h5>
                                {
                                    months[
                                        new Date(
                                            data.wordpressPost.date
                                        ).getMonth()
                                    ]
                                }{' '}
                                {new Date(data.wordpressPost.date).getDate()},
                                {new Date(
                                    data.wordpressPost.date
                                ).getFullYear()}
                            </h5>
                        </div>
                        <img
                            className="blogline"
                            src={LineFull}
                            alt="linefull"
                        />
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.wordpressPost.content,
                            }}
                        />
                    </div>
                    <div className="blogsidebar">
                        <SidebarCategories />
                        <Search />
                        <Archives />
                  {/*      <Schedule /> */}
                        <Shop />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default blog;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPost(id: { eq: $id }) {
            featured_media {
                localFile {
                    childImageSharp {
                        fluid {
                            src
                        }
                    }
                }
            }
            categories {
                name
            }
            date
            excerpt
            slug
            content
            title
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
