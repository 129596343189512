import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import '../styles/app.scss';
import SEO from '../components/seo';
import SidebarCategories from '../components/sidebar/Categories';
import Search from '../components/sidebar/Search';
import Archives from '../components/sidebar/Archives';
{/*import Schedule from '../components/sidebar/Schedule';*/}
import Shop from '../components/sidebar/Shop';

const CategoryPage = props => {
    const { data, pageContext } = props;
    const { name: category } = pageContext;
    const months = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];
    return (
        <>
            <SEO title={`${category} archive`} />
            <HeaderMain />
            <HeroSingle pageTitle={category} />
            <section className="page-section">
                <div className="container blogwrapper">
                    <div className="bloglftwrap">
                        {data.allWordpressPost.nodes.map(post => (
                            <div className="bloglft">
                                <div className="blogimg">
                                    <Img
                                        fluid={
                                            post.featured_media.localFile
                                                .childImageSharp.fluid
                                        }
                                        alt="autism"
                                    />
                                </div>
                                <div className="bloxexc">
                                    <Link
                                        to={`/${post.slug}`}
                                        className="postName"
                                    >
                                        <h2
                                            dangerouslySetInnerHTML={{
                                                __html: post.title,
                                            }}
                                        />
                                    </Link>
                                    <h5>
                                        {months[new Date(post.date).getMonth()]}{' '}
                                        {new Date(post.date).getDate()},
                                        {new Date(post.date).getFullYear()}
                                    </h5>
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: post.excerpt,
                                        }}
                                    />
                                    <Link to={`/${post.slug}`}>Read More</Link>
                                </div>
                                <img
                                    className="blogline"
                                    src={LineFull}
                                    alt="linefull"
                                />
                            </div>
                        ))}
                    </div>

                    <div className="blogsidebar">
                        <SidebarCategories />
                        <Search />
                        <Archives />
                {/*        <Schedule />   */}
                        <Shop />
                    </div>
                </div>
            </section>
            <Footer />
        </>
    );
};

export default CategoryPage;

export const pageQuery = graphql`
    query CategoryPage($slug: String!) {
        allWordpressPost(
            filter: { categories: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            nodes {
                featured_media {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                srcSetWebp
                                src
                                srcSet
                            }
                        }
                    }
                }
                categories {
                    name
                    slug
                }
                date
                excerpt
                slug
                title
                id
            }
        }
    }
`;
